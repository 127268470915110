import React from "react";
import FingerprintJS, {Agent} from "@fingerprintjs/fingerprintjs"
import {UserLocationProp, UserLocationState} from "./user-location-types";
import {Point} from "ol/geom";

export class UserLocationComponent extends React.PureComponent<UserLocationProp, UserLocationState> {

    private readonly fingerprintPromise: Promise<Agent>;

    constructor(props: UserLocationProp, context: any) {
        super(props, context);
        this.fingerprintPromise = FingerprintJS.load()
    }

    componentDidMount() {
        this.fingerprintPromise.then(
            p => {
                p.get().then(result => {
                    this.setState({
                        fingerprint: result.visitorId
                    })
                })
            }
        )

        navigator.geolocation.getCurrentPosition(position => {
            this.setState({
                location: {
                    latitude: position.coords.latitude,
                    longitude: position.coords.longitude
                }
            })
            this.props.updateLocationCallback(new Point([position.coords.longitude, position.coords.latitude]))
        });
    }

    componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<UserLocationState>, snapshot?: any) {
        const requestOptions = {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: ""
        };
        if (this.state !== null) {
            if (this.state["fingerprint"] !== undefined
                && this.state["location"] !== undefined) {
                requestOptions.body = JSON.stringify({
                    fingerprint: this.state.fingerprint,
                    location: {
                        lt: this.state.location.latitude,
                        ln: this.state.location.longitude
                    }
                });
            } else if (this.state["fingerprint"] !== undefined
                && this.state["location"] === undefined) {
                requestOptions.body = JSON.stringify({
                    fingerprint: this.state.fingerprint
                });
            } else if (this.state["location"] !== undefined
                && this.state["fingerprint"] === undefined) {
                requestOptions.body = JSON.stringify({
                    location: {
                        lt: this.state.location.latitude,
                        ln: this.state.location.longitude
                    }
                });
            }
            fetch(process.env.REACT_APP_LOCATION_BACKEND_URL + "/api/v1/location", requestOptions)
                .catch(error => console.log(error))
        }
    }

    render() {
        return null;
    }
}