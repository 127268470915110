import Map from 'ol/Map';
import View from 'ol/View';
import TileLayer from 'ol/layer/Tile';
import XYZ from 'ol/source/XYZ';
import React from "react";
import {SantaMapContext, SantaMapProps, SantaMapState} from "./santa-map-types";
import {SantaLayer} from "./layers";
import "ol/ol.css";
import "./santa-map.css";
import {Vector as VectorLayer} from "ol/layer";
import {Vector as SourceVector} from "ol/source";
import {GeoJSON} from "ol/format";
import {Fill, Stroke, Style} from "ol/style";
import {Point} from "ol/geom";
import {UserLayer} from "./layers/user-layer";

export const MapContext = React.createContext<SantaMapContext | void>(undefined);

export class SantaMapComponent extends React.PureComponent<SantaMapProps, SantaMapState> {

    state: SantaMapState = {};
    private readonly mapDivRef: React.RefObject<HTMLDivElement>
    private readonly santaInitialPoint: Point;

    private readonly tileLayer: TileLayer<XYZ>;
    private readonly cityBorderLayer: VectorLayer<SourceVector>;
    private readonly bottomLayer: VectorLayer<SourceVector>;
    private readonly rightLayer: VectorLayer<SourceVector>;
    private readonly leftLayer: VectorLayer<SourceVector>;

    constructor(props: SantaMapProps, context: any) {
        super(props, context);
        this.mapDivRef = React.createRef<HTMLDivElement>()
        this.santaInitialPoint = new Point([Number(process!.env!.REACT_APP_INITIAL_POSITION_LATITUDE).valueOf(), Number(process!.env!.REACT_APP_INITIAL_POSITION_LONGITUDE).valueOf()]);

        this.tileLayer = new TileLayer({
            source: new XYZ({
                url: "https://{a-c}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            })
        })

        this.cityBorderLayer = new VectorLayer({
            source: new SourceVector({
                url: '../geo-json/usa-nj-union-new-providence.json',
                format: new GeoJSON()
            }),
            style: new Style({
                fill: new Fill({
                    color: 'rgba(255,0,0,0)'
                }),
                stroke: new Stroke({
                    color: 'rgba(255,0,0,1)',
                    width: 3
                })
            })
        });

        this.bottomLayer = new VectorLayer({
            source: new SourceVector({
                url: '../geo-json/usa-nj-union-new-providence-bottom-zone.json',
                format: new GeoJSON()
            }),
            style: new Style({
                fill: new Fill({
                    color: 'rgb(72,255,0,0.15)'
                }),
                stroke: new Stroke({
                    color: 'rgb(72,255,0, 0)',
                    width: 3
                })
            })
        });

        this.rightLayer = new VectorLayer({
            source: new SourceVector({
                url: '../geo-json/usa-nj-union-new-providence-right-zone.json',
                format: new GeoJSON()
            }),
            style: new Style({
                fill: new Fill({
                    color: 'rgba(255,245,34,0.15)'
                }),
                stroke: new Stroke({
                    color: 'rgb(72,255,0, 0)',
                    width: 3
                })
            })
        });

        this.leftLayer = new VectorLayer({
            source: new SourceVector({
                url: '../geo-json/usa-nj-union-new-providence-left-zone.json',
                format: new GeoJSON()
            }),
            style: new Style({
                fill: new Fill({
                    color: 'rgba(110,200,255,0.15)'
                }),
                stroke: new Stroke({
                    color: 'rgb(72,255,0, 0)',
                    width: 3
                })
            })
        });
    }

    componentDidMount() {
        if (!this.mapDivRef.current) {
            return;
        }

        var layers = [this.tileLayer, this.cityBorderLayer]
        if (process.env.REACT_APP_SHOW_ZONES?.toLowerCase() === 'true') {
            layers.push(this.bottomLayer)
            layers.push(this.rightLayer)
            layers.push(this.leftLayer)
        }


        const map = new Map({
            target: this.mapDivRef.current,
            layers: layers,
            view: new View({
                center: [-8282748.98, 4968001.05],
                zoom: 15
            }),

        });

        const mapContext: SantaMapContext = {
            map: map
        }
        this.setState({
            mapContext: mapContext
        })
    }

    render() {
        return (
            <div className="map" ref={this.mapDivRef}>
                {this.state.mapContext && (
                    <MapContext.Provider value={this.state.mapContext}>
                        <UserLayer location={this.props.userLocation}/>
                        <SantaLayer initialPoint={this.santaInitialPoint} showClosest={process.env.REACT_APP_SHOW_CLOSEST?.toLowerCase() === 'true'} locationShowClosestPointTo={this.props.userLocation}/>
                    </MapContext.Provider>
                )}
            </div>
        );
    }
}



