import {
    SantaLayerComponentProps,
    SantaLayerComponentState,
    VectorLayerProps,
    EntityPayload
} from "./santa-layer-types";
import React from "react";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import {MapContext} from "../../santa-map";
import {SantaMapContext} from "../../santa-map-types";
import {Geometry, Point} from "ol/geom";
import {Feature} from "ol";
import {Icon, Style} from "ol/style";
import {LinearBackoff, WebsocketBuilder} from "websocket-ts";
import {point, toMercator} from "@turf/turf";
import {Vector} from "ol/source";

class SantaLayerComponent extends React.PureComponent<SantaLayerComponentProps, SantaLayerComponentState> {
    layer: VectorLayer<VectorSource<Feature<Geometry>>> | undefined;
    source: VectorSource<Feature<Geometry>> | undefined;
    counter: number = 0;
    private readonly defaultEntityPayload: EntityPayload;

    constructor(props: SantaLayerComponentProps, context: any) {
        super(props, context);
        this.state = {
            entitiesData: new Map()
        };
        this.defaultEntityPayload = {
            id: "xxx",
            icon: "santa-claus",
            location: {
                lt: this.props.initialPoint.getCoordinates()[0],
                ln: this.props.initialPoint.getCoordinates()[1]
            }
        }

    }

    toMercatorPoint(value: Point) {
        const position = toMercator(point(value.getCoordinates()));
        return new Point([
            position.geometry.coordinates[0],
            position.geometry.coordinates[1]
        ]);
    }

    componentDidMount() {
        new WebsocketBuilder(process.env.REACT_APP_BACKEND_URL + "/websocket")
            .withBackoff(new LinearBackoff(0, 1000, 8000))
            /*.onOpen((i, ev) => {
                console.log("opened")
            })*/
            .onClose((i, ev) => {
                setTimeout(() => {
                    this.setState({
                        entitiesData: new Map([[this.defaultEntityPayload.id, this.defaultEntityPayload]])
                    })
                }, 60000)
            })
            /*.onError((i, ev) => {
                console.log("error")
            })*/
            .onMessage((i, ev) => {
                //console.log("message");
                this.handleNewLocation(JSON.parse(ev.data));
            })
            /*.onRetry((i, ev) => {
                console.log("retry")
            })*/
            .build();
        this.source = new VectorSource({
            features: []
        });
        this.layer = new VectorLayer({
            source: this.source
        });
        this.props.map.addLayer(this.layer);
        this.setState({
            backupEntityId: this.defaultEntityPayload.id,
            entitiesData: new Map([[this.defaultEntityPayload.id, this.defaultEntityPayload]])
        })
    }

    getRandomEntityPayload(entityPayload: EntityPayload[]): EntityPayload {
        const keys = Array.from(entityPayload.values())
        const randomEntityPayload = keys[Math.floor(Math.random() * keys.length)]
        this.setState({
            backupEntityId: randomEntityPayload.id
        })
        return randomEntityPayload
    }

    handleNewLocation(entityPayload: EntityPayload[]) {
        if (entityPayload.length > 1) {
            entityPayload = entityPayload.filter(x=> x.id !== "-1")
        }
        if (this.props.showClosest) {
            let closestEntity: EntityPayload | undefined = undefined
            if (this.props.locationShowClosestPointTo) {
                const iconPoints = Array.from(entityPayload.values()).map(value => new Feature({geometry: new Point([value.location.ln, value.location.lt])}))
                const iconsVector = new Vector({
                    features: iconPoints
                })
                const closestIcon = iconsVector.getClosestFeatureToCoordinate(this.props.locationShowClosestPointTo.getCoordinates())
                closestEntity = entityPayload.find(x => x.location.ln === closestIcon.getGeometry()?.getCoordinates()[0] && x.location.lt === closestIcon.getGeometry()?.getCoordinates()[1])
            } else if (!this.state.backupEntityId) {
                closestEntity = this.getRandomEntityPayload(entityPayload)
            } else {
                closestEntity = entityPayload.find(x => x.id === this.state.backupEntityId)
                if (!closestEntity) {
                    closestEntity = this.getRandomEntityPayload(entityPayload)
                }
            }
            this.setState({
                entitiesData: new Map([[closestEntity!.id, closestEntity!]])
            });
        } else {
            this.setState({
                entitiesData: new Map(entityPayload.map((entity) => [entity.id, entity]))
            });
        }
    }

    render() {
        const santaIconFeatures: Feature[] = []
        for (const value of this.state.entitiesData.values()) {
            const santaIconFeature = new Feature({
                geometry: this.toMercatorPoint(new Point([value.location.ln, value.location.lt]))
            });
            const santaIconStyle = new Style({
                image: new Icon({
                    src: `../${value.icon}-48.png`
                }),
            });
            santaIconFeature.setStyle(santaIconStyle);
            santaIconFeatures.push(santaIconFeature)
        }

        this.source?.clear();
        this.source?.addFeatures(santaIconFeatures);
        return null;
    }
}

export const SantaVectorLayerWithContext = (props: VectorLayerProps) => {
    return (
        <MapContext.Consumer>
            {(mapContext: SantaMapContext | void) => {
                if (mapContext) {
                    return <SantaLayerComponent {...props} map={mapContext.map}/>;
                }
            }}
        </MapContext.Consumer>
    )
}