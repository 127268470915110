import {UserLayerComponentProps, UserVectorLayerProps} from "./user-layer-types";
import React from "react";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import {MapContext} from "../../santa-map";
import {SantaMapContext} from "../../santa-map-types";
import {Geometry, Point} from "ol/geom";
import {Icon, Style} from "ol/style";
import Feature from "ol/Feature";
import {point, toMercator} from "@turf/turf";


class UserLayerComponent extends React.PureComponent<UserLayerComponentProps, any> {
    layer: VectorLayer<VectorSource<Feature<Geometry>>> | undefined;
    source: VectorSource<Feature<Geometry>> | undefined;
    counter: number = 0;

    componentDidMount() {
        this.source = new VectorSource();
        this.layer = new VectorLayer({
            source: this.source
        });
        this.props.map.addLayer(this.layer);
    }

    render() {
        if (this.props.location !== undefined) {
            const mercatorLocation = toMercator(point(this.props.location.getCoordinates()))
            const pinIconFeature = new Feature({
                geometry: new Point([mercatorLocation.geometry.coordinates[0], mercatorLocation.geometry.coordinates[1]])
            });
            const pinIconStyle = new Style({
                image: new Icon({
                    src: '../pin-48.png'
                }),
            });
            pinIconFeature.setStyle(pinIconStyle);

            this.source?.clear();
            this.source?.addFeature(pinIconFeature);
        }
        return null;
    }
}

export const UserVectorLayerWithContext = (props: UserVectorLayerProps) => {
    return (
        <MapContext.Consumer>
            {(mapContext: SantaMapContext | void) => {
                if (mapContext) {
                    return <UserLayerComponent {...props} map={mapContext.map}/>;
                }
            }}
        </MapContext.Consumer>
    )
}