import React from "react";
import "./facebook-button.css"
import {FacebookButtonProps} from "./facebook-button-types";
import {FacebookShareButton} from "react-share";
import ReactGA from "react-ga4";

export class FacebookButtonController extends React.PureComponent<FacebookButtonProps> {

    render() {
        return (
            <FacebookShareButton

                className="facebook-button"
                hashtag="#SantaClausNPFD"
                children={this.props.buttonText}
                url={this.props.url}
                resetButtonStyle={false}
                beforeOnClick={ () =>
                    ReactGA.event("select_content", {
                        content_type: "Facebook Share Button Click"
                    })
                }
            />
        )
    }
}
