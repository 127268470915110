import React from 'react';
import ReactGA from 'react-ga4'
import {DonationButton} from "./donation-button";
import {UserLocation} from "./user-location"
import "./App.css"
import {SantaMap} from "./santa-map";
import {FacebookButton} from "./facebook-button";
import {AppState} from "./App-types";
import {Point} from "ol/geom";


ReactGA.initialize('G-PNWV3GC395')
ReactGA.send("pageview");

class App extends React.Component<any, AppState> {

    constructor(props: any, context: any) {
        super(props, context);
        this.state = {
            userLocation: undefined
        };
    }

    updateUserLocation(location: Point) {
        this.setState({
            userLocation: location
        })
    }

    render() {
        return (
            <div>
                <SantaMap userLocation={this.state.userLocation}/>
                <DonationButton
                    url={process.env.REACT_APP_DONATION_BUTTON_URL as string}
                    text={process.env.REACT_APP_DONATION_BUTTON_TEXT as string}
                />
                <UserLocation updateLocationCallback={(location: Point) => this.updateUserLocation(location)}/>
                <FacebookButton
                    buttonText={process.env.REACT_APP_FACEBOOK_SHARE_BUTTON_TEXT as string}
                    quote="Follow Santa with me"
                    url={process.env.REACT_APP_FACEBOOK_SHARE_URL as string}
                />
            </div>
        );
    }
}

export default App;
