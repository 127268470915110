import React from "react";
import "./donation-button.css"
import {DonationButtonProps} from "./donation-button-types";
import ReactGA from "react-ga4";

export class DonationButtonComponent extends React.PureComponent<DonationButtonProps> {

    render() {
        return (
            <button id="donation-button" className="name noselect"
                    onClick={() => {
                        window.open(this.props.url, '_blank');
                        ReactGA.event("select_content", {
                            content_type: "Donation Button Click"
                        });
                    }}>
                {this.props.text}
            </button>
        )
    }
}
